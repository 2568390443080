export const useTruncate = (text: string, truncateSize: number) => {
  if (text) {
    if (text.length <= truncateSize) {
      return text
    }
    const start = Math.floor((truncateSize - 3) / 2)
    const end = text.length - Math.ceil((truncateSize - 3) / 2)
    return text.slice(0, start) + '...' + text.slice(end)
  }
}
